<script lang="ts">
	import { onMount, onDestroy } from 'svelte';
	import { PUBLIC_DIFFUSION_API_ENDPOINT } from '$env/static/public';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import posthog from 'posthog-js';
	import authStore from '$lib/authStore';
	import { Client as DiffusionApiClient } from '$lib/diffusionApi';
	import Header from '$components/Header.svelte';
	import PbrMaterialGraphic from '$components/PBRMaterialGraphic.svelte';
	import VoxelArtGraphic from '$components/VoxelArtGraphic.svelte';

	let subscriptions: (() => void)[] = [];
	let diffusionApiClient: DiffusionApiClient | undefined = undefined;

	let earlyAccessIsOnline: boolean | undefined = undefined;
	let userHasAccess: boolean | undefined = undefined;

	onMount(async () => {
		console.log('homepage onmount');
		localStorage.setItem('redirectUrl', $page.url.pathname);

		posthog.init('phc_p7EDVq5HcrSd2qzxX8rcNj7kbAO2q5k1E1YcQptdA72', {
			api_host: 'https://app.posthog.com',
			session_recording: { maskAllInputs: false }
		});

		document.body.style.overflowY = 'auto';
		document.body.style.background = '#e6e4d6';

		subscriptions.push(
			authStore.subscribe(async (auth) => {
				const user = auth.user;

				// we don't know their sign-in status yet
				if (user === undefined) {
					return;
				}

				// user is not logged in
				if (user === null) {
					earlyAccessIsOnline = true;
					userHasAccess = false;
					return;
				}

				if (user.email) {
					posthog.identify(user.email);
				}

				// landing is only 'true' when they navigate to this page from the header
				// so, if they're logged in and go to iliad.ai, we redirect them to /home
				if ($page.url.searchParams.get('landing') !== 'true') {
					goto('/home');
				}

				// user is logged in
				diffusionApiClient = new DiffusionApiClient({
					BASE: PUBLIC_DIFFUSION_API_ENDPOINT,
					TOKEN: await user.getIdToken()
				});
				const response = await diffusionApiClient.default.getBetaInfoBetaInfoGet();
				earlyAccessIsOnline = response.online;
				userHasAccess = response.user_has_access;
			})
		);
	});

	onDestroy(() => {
		for (const _ of subscriptions) {
			_();
		}
	});
</script>

<!-- <div>Create concept art for your pitch deck.</div>
<div>Generate assets for your brand.</div>
<div>Construct powerful generative workflows.</div>
<div>Build your own design system.</div>
<div>Collaborate in real-time.</div> -->

<!-- Generate unique, seamlessly tiled texture packs instantly. -->

<!-- Generate dynamic image assets from your imagination with Iliad.-->

<!-- Make exactly what you need-->
<!-- Use text prompting, color palette selection, and
<b class="text-[#a0ffa0]">mid-generation brush strokes</b> to control the final result. -->

<!-- Switch to <b class="text-[#a0ffa0]">canvas mode</b> to rapidly sketch characters and environments. -->

<!--
	It's easy to use and the most advanced tool of its kind.
	Iterate on your work with speed and precision. Turn back time with mid-generation
		editing.
-->

<!-- <div id="description-section">
	<div>Built for AI whisperers.</div>
	<div>[upscale] [img2img] [color palette] [brush guidance]</div>
</div> -->

<body class="w-screen h-fit min-h-screen font-inter bg-primary-100">
	<div id="page" class="w-screen h-fit min-h-screen flex flex-col">
		<div class="w-full h-12 p-2 flex flex-row">
			{#if $authStore.isLoggedIn}
				<Header />
			{/if}
			{#if earlyAccessIsOnline !== undefined && userHasAccess !== undefined && !$authStore.isLoggedIn}
				<button
					class="w-fit h-8 px-4 border rounded text-zinc-900 bg-zinc-200 hover:bg-zinc-100 border-zinc-400 font-semibold text-xs shadow-md"
					on:click={() => goto('/login')}
				>
					Sign in <i class="ml-1 fas fa-sign-in-alt" />
				</button>
			{/if}
		</div>
		<div class="w-screen h-fit flex flex-col justify-start items-center">
			<div class="h-8" />
			<img
				class="inline w-40 h-40 md:w-64 md:h-64"
				src="https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/7ee54832-e1c5-400a-dd84-2865d3bfaa00/public"
				alt=""
			/>
			<div class="h-8" />
			<div id="headline-section" class="w-screen h-fit flex flex-col justify-center items-center">
				<div
					class="max-w-[425px] md:max-w-[525px] px-4 md:px-2 text-4xl md:text-5xl text-center font-garamond text-zinc-900"
				>
					Iliad is a creative tool for controllable art generation.
				</div>
			</div>
			<div class="h-8 md:h-16" />
			<div class="w-screen h-fit flex flex-row justify-center items-center">
				<div id="main-action-section" class="w-5/6 h-96 absolute bg-cover" />
				<div class="w-screen h-96 flex flex-col justify-center items-center opacity-[99.99%]">
					<div class="w-fit h-fit flex flex-col justify-center items-center">
						{#if earlyAccessIsOnline === undefined || userHasAccess === undefined}
							<!-- placeholder - information hasn't loaded yet -->
							<div class="w-fit h-10 flex flex-row justify-start items-center" />
							<div
								class="w-32 h-16 mt-2 px-3 flex flex-row justify-center items-center border rounded text-lg text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
							>
								<div class="w-20 h-2 bg-primary-700 rounded animate-pulse" />
							</div>
						{:else if $authStore.isLoggedIn}
							{#if earlyAccessIsOnline && userHasAccess}
								<div class="h-10 flex flex-row justify-center items-center text-sm text-zinc-900">
									<div
										class="w-6 h-6 flex flex-row justify-center items-center bg-zinc-700 text-xs rounded-xl shadow-md"
									>
										<i class="fa-solid fa-check" style="color: #a0ffa0" />
									</div>
									<div class="ml-2">You have early access to Iliad.</div>
								</div>
								<button
									class="w-44 h-16 mt-2 px-3 flex flex-row justify-center items-center border rounded text-md text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
									on:click={(_) => goto('/workspace')}
								>
									Go to workspace
								</button>
							{/if}
							{#if earlyAccessIsOnline && !userHasAccess}
								<div class="h-10" />
								<div
									class="w-fit h-16 mt-2 px-8 flex flex-row justify-start items-center bg-zinc-800 text-zinc-50 rounded"
								>
									<i class="fa-solid fa-check" style="color: #a0ffa0" />
									<div class="ml-3 text-md">You're on the early access waitlist.</div>
								</div>
							{/if}
							{#if !earlyAccessIsOnline}
								<div class="w-fit h-10 mt-2 flex flex-row justify-start items-center" />
							{/if}
						{:else}
							<div class="w-fit h-10 flex flex-row justify-start items-center">
								<div class="text-sm text-center" />
							</div>
							<button
								class="w-44 h-16 mt-2 px-3 flex flex-row justify-center items-center border rounded text-md text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
								on:click={(_) => goto('https://iliadai.typeform.com/to/ka0usAXl')}
							>
								Get early access
							</button>
						{/if}
						<div class="flex flex-row mt-2">
							<a
								class="w-fit h-10 flex flex-row justify-start items-center text-zinc-700 hover:text-zinc-500"
								href="https://discord.gg/5rQP6KTR4X"
							>
								<i class="fa-brands fa-discord" />
							</a>
							<a
								class="w-fit h-10 ml-4 flex flex-row justify-start items-center text-zinc-700 hover:text-zinc-500"
								href="https://www.instagram.com/iliad.ai/"
							>
								<i class="fa-brands fa-instagram" />
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="h-12 md:h-28" />
			<div class="w-screen h-fit flex flex-row justify-center items-center">
				<div id="product-screenshot-section" class="w-5/6 h-96 absolute bg-cover" />
				<div
					class="
							w-96 h-fit flex flex-col
							lg:w-3/4 lg:h-[40rem] lg:ml-10 lg:flex-row
							justify-center items-center opacity-[99.99%]
						"
				>
					<div
						class="
								w-full flex flex-col justify-center items-center text-center
								lg:w-1/3 lg:items-start lg:text-left
								max-w-2xl
							"
					>
						<div
							class="
								text-xl
								md:text-2xl
							"
						>
							Mid-generation editing
						</div>
						<div class="w-full h-8" />
						<div class="w-72 max-w-lg flex flex-col justify-center items-center">
							<p>Guide images as they're being formed into existence.</p>
							<div class="h-6" />
							<p>
								Step through and edit the evolution of images you're generating for fine control
								over the final output.
							</p>
						</div>
					</div>
					<div class="w-0 h-16 lg:h-0 lg:w-16" />
					<div
						id="product-screenshot"
						class="w-fit h-fit flex flex-row justify-center items-center"
					>
						<img
							class="rounded-md shadow-2xl border-l-[4px] border-r-[5px] border-t-[3px] border-b-[8px] border-navy-900"
							alt=""
							src="https://storage.iliad.ai/mobile-mushroom.png"
						/>
					</div>
				</div>
			</div>
			<div class="h-52 lg:h-32" />
			<div
				class="
					w-screen h-fit py-16 flex flex-col-reverse justify-center items-center
					lg:flex-row
				"
			>
				<div id="voxel-art-section" class="w-5/6 h-96 absolute bg-cover" />
				<div
					class="w-80 h-80 flex flex-row justify-start items-start opacity-[99.99%] bg-zinc-900 rounded-lg shadow-lg"
				>
					<div class="w-6" />
					<div class="translate-y-[-4rem]">
						<VoxelArtGraphic
							width={150}
							height={350}
							cameraPosition={[0, 0, 35]}
							containerName="orb-staff-container"
							gltfUrl="https://storage.iliad.ai/orb-staff.gltf"
						/>
					</div>
					<div class="flex flex-col justify-start items-center translate-y-[-2rem]">
						<div class="h-12" />
						<VoxelArtGraphic
							width={100}
							height={150}
							cameraPosition={[0, 0, 25]}
							containerName="mask-container"
							gltfUrl="https://storage.iliad.ai/mask.gltf"
						/>
						<VoxelArtGraphic
							width={100}
							height={150}
							cameraPosition={[0, 0, 25]}
							containerName="blob-container"
							gltfUrl="https://storage.iliad.ai/blob.gltf"
						/>
					</div>
				</div>
				<div class="lg:w-24 h-12" />
				<div
					class="w-64 max-w-lg lg:max-w-2xl flex flex-col justify-center items-center opacity-[99.99%]"
				>
					<div
						class="
						w-full
						text-xl
						md:text-2xl
						text-center
						lg:text-left
					"
					>
						Voxel art
					</div>
					<div class="w-full h-8" />
					<div
						class="
						w-full flex flex-col justify-center items-center text-center
						lg:text-left
					"
					>
						<p>Transform 2D images into 3D voxel art in less than a minute.</p>
					</div>
				</div>
			</div>
			<div class="h-52 lg:h-0" />
			<div class="w-screen h-fit flex flex-row justify-center items-center">
				<div id="texture-authoring-section" class="w-5/6 h-96 absolute bg-cover" />
				<div
					class="
							w-96 h-fit flex flex-col
							lg:w-3/4 lg:h-[40rem] lg:ml-10 lg:flex-row
							justify-center items-center opacity-[99.99%]
						"
				>
					<div
						class="
								w-full flex flex-col justify-center items-center text-center
								lg:w-1/3 lg:items-start lg:text-left
								max-w-2xl
							"
					>
						<div
							class="
								text-xl
								md:text-2xl
							"
						>
							Texture authoring
						</div>
						<div class="w-full h-8" />
						<div class="w-72 max-w-lg lg:max-w-lg flex flex-col justify-center items-center">
							<p>Create unique, seamless textures instantly.</p>
						</div>
					</div>
					<div class="w-96 h-96 flex flex-row justify-center items-center">
						<PbrMaterialGraphic width={300} height={300} transitionDuration={3000} />
					</div>
				</div>
			</div>
			<div class="h-12 lg:h-0" />
			<div
				class="
						w-screen h-fit py-16 flex flex-col-reverse justify-center items-center
						lg:flex-row
					"
			>
				<div id="concept-art-section" class="w-5/6 h-96 absolute bg-cover" />
				<div
					class="
						w-fit h-fit my-8 flex flex-col justify-center items-center opacity-[99.99%]
						lg:flex-row
					"
				>
					<div id="concept-art" class="w-80 h-52 sm:w-96 sm:h-64 rounded-lg shadow-2xl" />
				</div>
				<div class="lg:w-16" />
				<div
					class="w-64 max-w-lg lg:max-w-2xl flex flex-col justify-center items-center opacity-[99.99%]"
				>
					<div
						class="
							w-full
							text-xl
							md:text-2xl
							text-center
							lg:text-left
						"
					>
						Concept art
					</div>
					<div class="w-full h-8" />
					<div
						class="
							w-full flex flex-col justify-center items-center text-center
							lg:text-left
						"
					>
						<p>Effortlessly iterate on characters and environments.</p>
					</div>
				</div>
			</div>
			<div class="h-0 md:h-12" />
			<div class="w-screen h-fit py-28 flex flex-col justify-center items-center">
				<div id="video-section" class="w-5/6 h-96 absolute bg-cover" />
				<div
					class="
						w-full
						text-xl
						md:text-2xl
						text-center
						opacity-[99.99%]
					"
				>
					See how it works
				</div>
				<div
					class="
						w-full h-1/3 mt-12 flex flex-row justify-center items-center opacity-[99.99%]
						lg:w-2/3 lg:h-5/6
					"
				>
					<div class="flex flex-col justify-start items-center">
						<script src="https://unpkg.com/@mux/mux-player"></script>
						<mux-player
							id="demo-video-player"
							class="
								w-[350px] h-[197px]
								sm:w-[480px] sm:h-[270px]
								md:w-[640px] md:h-[360px]
								lg:w-[960px] lg:h-[540px]
								shadow-2xl rounded-lg
							"
							stream-type="on-demand"
							playback-id="300dZFATFveWGFyIiqEMmuLDweZO01BEFqcQpkwV6l01jg"
							metadata-video-title="Iliad Demo"
							metadata-viewer-user-id=""
							primary-color="#FAFAFA"
							secondary-color="#27272A"
							thumbnail-time="66"
						/>
					</div>
				</div>
			</div>
			<div class="w-screen h-fit flex flex-row justify-center items-center">
				<div id="main-action-section" class="w-5/6 h-64 absolute bg-cover" />
				<div class="w-screen h-64 flex flex-col justify-center items-center opacity-[99.99%]">
					<div class="w-fit h-fit flex flex-col justify-center items-center">
						{#if earlyAccessIsOnline === undefined || userHasAccess === undefined}
							<!-- placeholder - information hasn't loaded yet -->
							<div class="w-fit h-10 flex flex-row justify-start items-center" />
							<div
								class="w-32 h-16 mt-2 px-3 flex flex-row justify-center items-center border rounded text-lg text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
							>
								<div class="w-20 h-2 bg-primary-700 rounded animate-pulse" />
							</div>
						{:else if $authStore.isLoggedIn}
							{#if earlyAccessIsOnline && userHasAccess}
								<div class="h-10 flex flex-row justify-center items-center text-sm text-zinc-900">
									<div
										class="w-6 h-6 flex flex-row justify-center items-center bg-zinc-700 text-xs rounded-xl shadow-md"
									>
										<i class="fa-solid fa-check" style="color: #a0ffa0" />
									</div>
									<div class="ml-2">You have early access to Iliad.</div>
								</div>
								<button
									class="w-44 h-16 mt-2 px-3 flex flex-row justify-center items-center border rounded text-md text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
									on:click={(_) => goto('/workspace')}
								>
									Go to workspace
								</button>
							{/if}
							{#if earlyAccessIsOnline && !userHasAccess}
								<div class="h-10" />
								<div
									class="w-fit h-16 mt-2 px-8 flex flex-row justify-start items-center bg-zinc-800 text-zinc-50 rounded"
								>
									<i class="fa-solid fa-check" style="color: #a0ffa0" />
									<div class="ml-3 text-md">You're on the early access waitlist.</div>
								</div>
							{/if}
							{#if !earlyAccessIsOnline}
								<div class="w-fit h-10 mt-2 flex flex-row justify-start items-center" />
							{/if}
						{:else}
							<div class="w-fit h-10 flex flex-row justify-start items-center" />
							<button
								class="w-44 h-16 mt-2 px-3 flex flex-row justify-center items-center border rounded text-md text-zinc-50 shadow-md bg-zinc-800 hover:bg-zinc-700 border-zinc-700 hover:border-zinc-600"
								on:click={(_) => goto('https://iliadai.typeform.com/to/ka0usAXl')}
							>
								Get early access
							</button>
						{/if}
						<div class="flex flex-row mt-2">
							<a
								class="w-fit h-10 flex flex-row justify-start items-center text-zinc-700 hover:text-zinc-500"
								href="https://discord.gg/5rQP6KTR4X"
							>
								<i class="fa-brands fa-discord" />
							</a>
							<a
								class="w-fit h-10 ml-4 flex flex-row justify-start items-center text-zinc-700 hover:text-zinc-500"
								href="https://www.instagram.com/iliad.ai/"
							>
								<i class="fa-brands fa-instagram" />
							</a>
						</div>
					</div>
				</div>
			</div>
			{#if earlyAccessIsOnline === undefined || userHasAccess === undefined}
				<div class="flex flex-row mb-10" />
			{:else if $authStore.isLoggedIn}
				{#if userHasAccess}
					<div class="flex flex-row mb-10" />
				{:else}
					<div class="flex flex-row mb-10">
						<a class="h-10 text-zinc-600 hover:text-zinc-500 text-sm" href="/subscription">
							<i class="fa-solid fa-person-running" />
							<i class="fa-solid fa-door-open mr-2" />
							<u>Or, skip the line by becoming an early adopter.</u>
						</a>
					</div>
				{/if}
			{:else}
				<div class="flex flex-row mb-10">
					<a
						class="h-16 flex flex-row justify-center items-center text-zinc-600 hover:text-zinc-500 text-sm"
						href="/subscription"
					>
						<i class="fa-solid fa-person-running" />
						<i class="fa-solid fa-door-open mr-2" />
						<u>Or, skip the line by becoming an early adopter.</u>
					</a>
				</div>
			{/if}
			<!-- <div class="w-full max-w-[300px] h-16 mb-4 flex flex-row justify-center items-center text-xs">
				<a
					href="https://www.ycombinator.com/launches/IHC-iliad-creative-tool-for-generating-game-assets"
					class="flex flex-col justify-center items-center w-1/2 h-16"
				>
					<div class="h-6 flex flex-row justify-center items-center">Backed by</div>
					<div class="h-1" />
					<div class="h-6 flex flex-row justify-center items-center">
						<div
							class="w-6 h-6 flex flex-row justify-center items-center bg-[#ea6731] rounded text-zinc-50 shadow-md"
						>
							Y
						</div>
						<div class="ml-1 font-semibold">Combinator</div>
					</div>
				</a>
				<div class="flex flex-col justify-center items-center w-1/2 h-16">
					<a
						class="h-6 flex flex-row justify-center items-center text-zinc-600 hover:text-zinc-500"
						href="/privacy-policy"
						>Privacy Policy
					</a>
					<div class="h-1" />
					<a
						class="h-6 flex flex-row justify-center items-center text-zinc-600 hover:text-zinc-500"
						href="/terms-of-service"
					>
						Terms of Use
					</a>
				</div>
			</div> -->
		</div>
	</div>
</body>

<style>
	mux-player {
		aspect-ratio: 16 / 9;
		max-width: 100%;
		display: block;
		margin: auto;
		padding: 2px;
		background: #27272a;
	}
	body {
		margin: 0;
		padding: 0;
		background: #e6e4d6;
		color: #382a32;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	#concept-art {
		background-image: url('https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/c35e35c2-91b7-46f4-1271-6c275db62b00/public');
		background-size: 100%;
	}

	#page {
		background-image: linear-gradient(
			180deg,
			hsl(53deg 24% 87%) 0%,
			hsl(52deg 23% 86%) 11%,
			hsl(51deg 22% 86%) 22%,
			hsl(51deg 21% 85%) 33%,
			hsl(50deg 20% 85%) 44%,
			hsl(49deg 19% 84%) 56%,
			hsl(49deg 18% 84%) 67%,
			hsl(48deg 17% 83%) 78%,
			hsl(48deg 16% 83%) 89%,
			hsl(47deg 15% 82%) 100%
		);
	}
	#product-screenshot-section {
		background-image: url('https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/9698a2db-e1a3-42f8-3ce5-70233ceff100/public');
		background-repeat: no-repeat;
		background-position: center;
		mask-composite: intersect;
		mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
			linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);
	}
	#main-action-section {
		background-image: url('https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/88757315-172f-461b-d930-f946aa5c5800/public');
		background-repeat: no-repeat;
		background-position: center;
		mask-composite: intersect;
		mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
			linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);
	}

	#voxel-art-section {
		background-image: url('https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/a677e83c-795c-47c9-59ff-1eac6ca86b00/public');
		background-repeat: no-repeat;
		background-position: center;
		mask-composite: intersect;
		mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
			linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);
	}

	#texture-authoring-section {
		background-image: url('https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/f09c9e89-323b-4863-d8b3-76bbccb1dc00/public');
		background-repeat: no-repeat;
		background-position: center;
		mask-composite: intersect;
		mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
			linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);
	}

	#concept-art-section {
		background-image: url('https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/a677e83c-795c-47c9-59ff-1eac6ca86b00/public');
		background-repeat: no-repeat;
		background-position: center;
		mask-composite: intersect;
		mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
			linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);
	}

	#video-section {
		background-image: url('https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/66e33069-b01c-41ba-5128-984c6385bc00/public');
		background-repeat: no-repeat;
		background-position: center;
		mask-composite: intersect;
		mask-image: linear-gradient(0deg, transparent 0%, black 25%, black 75%, transparent 100%),
			linear-gradient(90deg, transparent 0%, black 50%, transparent 100%);
	}
</style>
