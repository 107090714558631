<script lang="ts">
	import { onMount, onDestroy } from 'svelte';
	import * as THREE from 'three';
	import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
	import { isWebGLAvailable } from '$lib/canvasUtilities';

	export let width: number = 200;
	export let height: number = 200;
	export let transitionDuration: number = 300;

	let renderer: THREE.WebGLRenderer;
	let manager: THREE.LoadingManager;
	let canvas: HTMLCanvasElement;
	let isLoaded = false;
	let isLoadedTime: number = 0;
	let isShutdown = false;
	let webGLNotAvailable = false;

	const diffuseUrl =
		'https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/40f46b77-6367-4824-c320-2148a4546a00/public';
	const heightUrl =
		'https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/fb51741a-a290-42c7-bbca-3542846f3b00/public';
	const edgeUrl =
		'https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/c7404823-83d9-41b0-fe9f-fd7bf405bf00/public';
	const normalUrl =
		'https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/d93dbb44-06ff-43b7-5638-39e8cbf4b800/public';
	const smoothnessUrl =
		'https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/c13663fb-c2d6-45c5-242f-9e0538027900/public';
	const metallicUrl =
		'https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/daeea878-2897-4ba9-d400-0d071c459800/public';
	const ambientOcclusionUrl =
		'https://imagedelivery.net/vSd3Qxd5RvTJoQinGHZ4vg/a7a0bfbe-5982-42d1-7cf4-b8b9c4b96600/public';

	const load = async () => {
		if (!isWebGLAvailable()) {
			webGLNotAvailable = true;
			return;
		}

		renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
		renderer.shadowMap.enabled = true;
		renderer.setSize(width, height);

		manager = new THREE.LoadingManager();

		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
		camera.position.z = 2.05;

		const environment = new RoomEnvironment();
		const pmremGenerator = new THREE.PMREMGenerator(renderer);
		scene.environment = pmremGenerator.fromScene(environment).texture;

		renderer.toneMapping = THREE.ACESFilmicToneMapping;

		const diffuseTexture = new THREE.TextureLoader(manager).load(diffuseUrl);
		const heightTexture = new THREE.TextureLoader(manager).load(heightUrl);
		const edgeTexture = new THREE.TextureLoader(manager).load(edgeUrl);
		const normalTexture = new THREE.TextureLoader(manager).load(normalUrl);
		const smoothnessTexture = new THREE.TextureLoader(manager).load(smoothnessUrl);
		const metallicTexture = new THREE.TextureLoader(manager).load(metallicUrl);
		const ambientOcclusionTexture = new THREE.TextureLoader(manager).load(ambientOcclusionUrl);

		manager.onLoad = () => {
			isLoaded = true;
			isLoadedTime = Date.now();
		};

		for (const texture of [
			diffuseTexture,
			heightTexture,
			edgeTexture,
			normalTexture,
			smoothnessTexture,
			metallicTexture,
			ambientOcclusionTexture
		]) {
			texture.wrapS = THREE.RepeatWrapping;
			texture.wrapT = THREE.RepeatWrapping;
			texture.repeat.set(2.0, 2.0);
		}

		const material = new THREE.MeshStandardMaterial({
			map: diffuseTexture,
			displacementMap: heightTexture,
			displacementScale: 0.18,
			displacementBias: 0.0,
			normalMap: normalTexture,
			roughness: 0.25,
			roughnessMap: smoothnessTexture,
			metalness: 1.0,
			metalnessMap: metallicTexture,
			aoMap: ambientOcclusionTexture,
			aoMapIntensity: 1.2,
			side: THREE.DoubleSide
		});

		const sphere = new THREE.SphereGeometry(1.0, 128, 128);
		const mesh = new THREE.Mesh(sphere, material);
		mesh.receiveShadow = true;
		scene.add(mesh);

		const light = new THREE.PointLight(0xffcccc, 3.0);
		light.position.set(50, 25, 25);
		scene.add(light);

		window.addEventListener('resize', () => {
			renderer.setSize(width, height);
			camera.aspect = width / height;
			camera.updateProjectionMatrix();
		});

		const render = () => {
			// check if renderer is disposed, if it is, return
			if (isShutdown) {
				if (renderer) {
					renderer.dispose();
				}
				return;
			}

			// if it's not loaded, return
			if (!isLoaded) {
				requestAnimationFrame(render);
				return;
			}

			mesh.rotation.y += 0.0012;
			renderer.render(scene, camera);
			const context = canvas.getContext('2d');
			if (!context) {
				return;
			}
			context.globalAlpha = Math.min((Date.now() - isLoadedTime) / transitionDuration, 1.0);
			context.drawImage(renderer.domElement, 0, 0);
			requestAnimationFrame(render);
		};
		render();
	};

	onMount(async () => {
		console.log('onMount for pbr material graphic');
		setTimeout(() => {
			load();
		}, 1000);
	});

	onDestroy(() => {
		isShutdown = true;
	});
</script>

{#if webGLNotAvailable}
	<div />
{:else}
	<div class="w-fit h-fit flex flex-row justify-center items-center">
		<canvas bind:this={canvas} {width} {height} />
	</div>
{/if}

<style>
</style>
