<script lang="ts">
	import { onMount, onDestroy } from 'svelte';
	import * as THREE from 'three';
	import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
	import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
	import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
	import { isWebGLAvailable } from '$lib/canvasUtilities';

	export let width: number = 200;
	export let height: number = 200;
	export let containerName: string;
	export let gltfUrl: string;
	export let cameraPosition: number[];

	let renderer: THREE.WebGLRenderer;

	let isLoaded = false;
	let isLoadedTime: number = 0;
	let isShutdown = false;
	let webGLNotAvailable = false;

	const load = async () => {
		if (!isWebGLAvailable()) {
			webGLNotAvailable = true;
			return;
		}

		renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
		renderer.shadowMap.enabled = true;
		renderer.setSize(width, height);

		const manager = new THREE.LoadingManager();

		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
		camera.position.set(0, 0, cameraPosition[2]);
		camera.lookAt(0, 0, 0);

		const container = document.getElementById(containerName);
		if (container) {
			container.appendChild(renderer.domElement);
		}

		// rotate the scene
		scene.rotation.x = -Math.PI / 2;

		renderer.toneMapping = THREE.ACESFilmicToneMapping;

		new GLTFLoader(manager).load(gltfUrl, (gltf) => {
			scene.add(gltf.scene);
		});

		manager.onLoad = () => {
			isLoaded = true;
			isLoadedTime = Date.now();
		};

		const controls = new OrbitControls(camera, renderer.domElement);
		controls.target.set(0, 0, 0);
		controls.enableDamping = true;
		// don't allow any vertical movement
		controls.minPolarAngle = Math.PI / 2;
		controls.maxPolarAngle = Math.PI / 2;

		// controls.autoRotate = true;
		// controls.autoRotateSpeed = 3.0;
		controls.enableZoom = false;
		controls.rotateSpeed = 0.5 + width / 300.0;
		controls.update();

		const light = new THREE.PointLight(0xffcccc, 3.0);
		light.position.set(50, 25, 25);
		scene.add(light);

		const environment = new RoomEnvironment();
		const pmremGenerator = new THREE.PMREMGenerator(renderer);
		scene.environment = pmremGenerator.fromScene(environment).texture;

		window.addEventListener(
			'resize',
			() => {
				if (renderer && camera) {
					renderer.setSize(width, height);
					camera.aspect = width / height;
					camera.updateProjectionMatrix();
				}
			},
			false
		);

		const render = () => {
			if (isShutdown) {
				if (renderer) {
					renderer.dispose();
				}
				return;
			}

			// if it's not loaded, return
			if (!isLoaded) {
				requestAnimationFrame(render);
				return;
			}

			requestAnimationFrame(render);
			controls.update();
			renderer.render(scene, camera);
		};
		render();
	};

	onMount(async () => {
		console.log('onMount for', containerName);
		setTimeout(() => {
			load();
		}, 1000);
	});

	onDestroy(() => {
		isShutdown = true;
	});
</script>

{#if webGLNotAvailable}
	<div />
{:else}
	<div class="cursor-ew-resize" id={containerName} />
{/if}

<style>
</style>
